import React from "react";

import MobileAppStoreImage from "../../images/icons/download-apple-app-store.svg";
import MobilePlayStoreImage from "../../images/icons/download-google-play-store.svg";

const MobileAppDownloadBtn = ({ idName, url, storeBtn, br, mt, mb, specialBtn }) => {
  let storeTitle = storeBtn === "apple" ? "Apple App Store" : "Google Play Store";

  let sMt = specialBtn === "Commercial Mobile Banking" ? 0 : mt;
  let sMb = specialBtn === "Commercial Mobile Banking" ? -7 : mb;

  let imageSrc = storeBtn === "apple" ? MobileAppStoreImage : MobilePlayStoreImage;

  return (
    <a
      id={"wafd-mobile-app-" + idName}
      href={url}
      className="btn store-app-btn"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        id={"Download WaFd Bank mobile app in " + storeTitle}
        src={imageSrc}
        style={{
          width: "135px",
          height: "40px",
          borderRadius: br + "px",
          marginTop: sMt + "px",
          marginBottom: sMb + "px",
          objectFit: "contain"
        }}
        alt=""
      />
      <span className="sr-only">{storeTitle}</span>
    </a>
  );
};

export default MobileAppDownloadBtn;

MobileAppDownloadBtn.defaultProps = {
  idName: "google-play-store",
  url: "/",
  imageSrc: "../../images/icons/download-google-play-store.svg",
  storeBtn: "google",
  br: 5, //Border radius
  mt: 0, //Margin top
  mb: 0 //Margin bottom
};
